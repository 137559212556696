@use './../../scss/' as *;

.base {
    min-width: 100%;
    height: 100%;
    background: $bg-item-1;
    border-radius: 20px;

    padding: 2rem;
    padding-top: 2.5rem;

    &.qna-visible {
        height: 500px;

        @include mobile {
            height: 60vh;
        }
    }

}